import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import axios from "axios";
import App from "./App";
import "styles/app.scss";
import { AuthProvider } from "components/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
