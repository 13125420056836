import React, { useCallback, useState } from 'react'
import { Button, ConfigProvider, Form, Progress, Radio, Select } from 'antd'
import enUS from "antd/locale/en_US";
import classNames from 'classnames';

import classes from './Run.module.scss'
import FormSection from 'components/form-section';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCOProjects, runCOProject } from 'service/completionOptimizer';

const OptimizerRunPage = () => {
  // const [progress, setProgress] = useState(null)
  const [form] = Form.useForm()

  const { data: projects } = useQuery({
    queryKey: ['co_getProjectList'],
    queryFn: () => getCOProjects()
  })

  const { mutate: run, isPending } = useMutation({
    mutationFn: (data) => runCOProject({ data })
  })

  const onSubmit = useCallback((data) => {
    run(data)
    // setProgress(50)
    // const mockProgressInterval = setInterval(() => {
    //   setProgress(value => {
    //     if (value >= 100) {
    //       clearInterval(mockProgressInterval)
    //       return null
    //     }
    //     return value + 10
    //   })
    // }, 1000);
  }, [run])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <Form
        form={form}
        className={classNames('px-4 py-2 overflow-y-scroll h-100', classes.form)}
        layout="vertical"
        labelAlign="left"
        onFinish={onSubmit}
      >
        <FormSection title="Run">
          <div className="vstack gap-4">
            <Form.Item name={['project']} label="Project">
              <Select options={projects?.map(p => ({
                value: p.pk,
                label: p.general?.title
              }))} />
            </Form.Item>
            <FormSection title="Computation Environment">
              <Form.Item name={['hardware']} label="Hardware">
                <Radio.Group>
                  <Radio value="CPU">CPU</Radio>
                  <Radio value="GPU">GPU</Radio>
                </Radio.Group>
              </Form.Item>
            </FormSection>
            <FormSection title="I/O Control">
              <Form.Item name={['op_frequency']} label="Output Frequency">
                <Radio.Group>
                  <Radio value="DAY">Day</Radio>
                  <Radio value="MONTH">Month</Radio>
                  <Radio value="YEAR">Year</Radio>
                </Radio.Group>
              </Form.Item>
            </FormSection>
            <div className="vstack align-items-center gap-2">
              {/* {progress !== null ? <Progress percent={progress} status="active" trailColor="#fefefe" showInfo={false} /> : null} */}
              <Button htmlType="submit" loading={isPending}>Run</Button>
            </div>
          </div>
        </FormSection>
      </Form>
    </ConfigProvider>
  )
}

export default OptimizerRunPage