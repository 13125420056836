import { Checkbox, Form, Select } from 'antd'
import FormSection from 'components/form-section'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import StageSetting from './StageSetting'

const HydraulicFractureSetting = () => {
  const form = Form.useFormInstance()
  const [currentStage, setCurrentStage] = useState(0)
  const [currentStageValues, setCurrentStageValues] = useState(() => {
    const value = form.getFieldValue(['Completion', 'hydraulic_fracture', currentStage])
    return value
  })
  const [isStageSynced, setIsStageSynced] = useState(currentStageValues?.__synced ?? false)

  const numberOfStages = Form.useWatch(['Completion', 'well', 'NUMBER_STAGES'])

  const stageSelections = useMemo(() => {
    return Array(numberOfStages ?? 0).fill(null).map((_, index) => ({
      value: index,
      label: `Stage ${ index + 1 }`
    }))
  }, [numberOfStages])

  useEffect(() => {
    if (!currentStageValues?.__synced) return

    const stageValues = form.getFieldValue(['Completion', 'hydraulic_fracture'])
    const syncValues = stageValues?.find(stage => stage.__synced)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage], syncValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStageValues?.__synced])

  const onSync = useCallback((e) => {
    const { checked } = e.target
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, '__synced'], checked)
    setIsStageSynced(checked)
    if (!checked) return
    const stageValues = form.getFieldValue(['Completion', 'hydraulic_fracture'])
    const syncValues = stageValues?.find((stage, index) => index !== currentStage && stage.__synced)
    if (!syncValues) return
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'STAGE_LEN'], syncValues.STAGE_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'NUMBER_CLUSTERS'], syncValues.NUMBER_CLUSTERS)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SAND_USAGE'], syncValues.SAND_USAGE)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_HALF_LEN'], syncValues.PRIMARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'VERTICAL_PENETRATION'], syncValues.PRIMARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_WIDTH'], syncValues.PRIMARY.FRACTURE_WIDTH)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_HALF_LEN'], syncValues.SECONDARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'VERTICAL_PENETRATION'], syncValues.SECONDARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_WIDTH'], syncValues.SECONDARY.FRACTURE_WIDTH)
  }, [currentStage, form])

  const onCopy = useCallback((fromIndex) => {
    const fromValues = form.getFieldValue(['Completion', 'hydraulic_fracture', fromIndex])
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'STAGE_LEN'], fromValues?.STAGE_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'NUMBER_CLUSTERS'], fromValues?.NUMBER_CLUSTERS)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SAND_USAGE'], fromValues?.SAND_USAGE)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_HALF_LEN'], fromValues?.PRIMARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'VERTICAL_PENETRATION'], fromValues?.PRIMARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_WIDTH'], fromValues?.PRIMARY.FRACTURE_WIDTH)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_HALF_LEN'], fromValues?.SECONDARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'VERTICAL_PENETRATION'], fromValues?.SECONDARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_WIDTH'], fromValues?.SECONDARY.FRACTURE_WIDTH)
  }, [currentStage, form])

  return (
    <div className="row">
      <div className="col-6">
        <FormSection
          title="Hydraulic fracture Stage Setting"
          extra={(
            <Checkbox checked={isStageSynced} onChange={onSync}>Sync with every Stage</Checkbox>
          )}
        >
          <div className="vstack gap-4">
            <div className="row">
              <div className="col-6">
                <Form.Item label="Select Stage">
                  <Select options={stageSelections} value={currentStage} onChange={(value) => {
                    const nextStageValue = form.getFieldValue(['Completion', 'hydraulic_fracture', value])
                    setCurrentStage(value)
                    setCurrentStageValues(nextStageValue)
                    setIsStageSynced(nextStageValue?.__synced ?? false)
                  }} />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item label="Copy from">
                  <Select options={stageSelections.filter(option => option.value !== currentStage)} onChange={onCopy} />
                </Form.Item>
              </div>
            </div>
            <Form.List name={['Completion', 'hydraulic_fracture']}>
              {(fields) => fields.map((field) => {
                return <StageSetting key={field.name} stageIndex={field.name} isActive={field.name === currentStage} />
              })}
            </Form.List>
          </div>
        </FormSection>
      </div>
      <div className="col-6">
        {/* <FractureDemo /> */}
      </div>
    </div>
  )
}

export default HydraulicFractureSetting