import { get, post, remove } from "./base";

const baseURL = 'http://62.169.24.57:2020/'

export function getCOProjects() {
  return get('/projects/settings/', {}, {
    baseURL
  }).then(res => res.data)
}

export function getCOProjectSettings(projectId, backupId) {
  return get(`/projects/edit/${ projectId }/${ backupId }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function upsertProjectSettings(data) {
  return post(`/projects/settings/create_or_update/`, data, {}, {
    baseURL
  })
}

export function runCOProject(data) {
  return post(`/run/create/`, data, {}, {
    baseURL
  })
}

export function getProjectNumOfStages(projectId) {
  return get(`/run/project_stages/${ projectId }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStagePlotResult(projectId, rateNo, stageNo) {
  return get(`/run/rate_${ rateNo }/${ projectId }/${ stageNo }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getProjectListWithResults() {
  return get('/run/results/', {}, {
    baseURL
  }).then(res => res.data)
}

export function deleteCOProject(projectId) {
  return remove(`/projects/settings/${ projectId }/delete/`, {}, {}, {
    baseURL
  }).then(res => res.data)
}

export function getWellPlotResult(projectId) {
  return get(`/run/well/rates/${ projectId }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dBase(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/base/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dOil(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/soil/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dPressure(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/pressure/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dGas(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/sgas/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dWater(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/swat/`, {}, {
    baseURL
  }).then(res => res.data)
}